import Headroom from "headroom.js";
import AOS from "aos";
import Alpine from "alpinejs";
import feather from "feather-icons";

window.Alpine = Alpine;

function setWithExpiry(key, value, ttl) {
	const now = new Date()

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	}
	localStorage.setItem(key, JSON.stringify(item))
}

function getWithExpiry(key) {
	const itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key)
		return null
	}
	return item.value
}

document.addEventListener("alpine:init", () => {
    Alpine.data("surveyPopup", () => ({
        init() {
            this.popupId = this.$el.getAttribute('data-popup-id');

            if (getWithExpiry(this.popupId)) {
                console.log('%c Popup has been closed by the user', 'background: #bada55; color: #000')                
            } else {
                setTimeout(() => {
                    this.surveyPopupOpen = true;
                }, 3000);
            }

            
        },
        surveyPopupOpen: false,
        popupId: null,

        openSurveyPopup() {
            this.surveyPopupOpen = true;
        },

        closeSurveyPopup() {
            setWithExpiry(this.popupId, true, 1000 * 60 * 60)
            this.surveyPopupOpen = false;
        },
    }));
});

document.addEventListener("DOMContentLoaded", () => {
    Alpine.start();
    feather.replace();
    AOS.init({
        duration: 1000,
        once: true,
        offset: 20,
    });
    initializeMapBox();
    initializeHeadroom();
});

const initializeHeadroom = () => {
    const header = document.querySelector(".headroom");
    const headroom = new Headroom(header);
    headroom.init();
};

const initializeMapBox = () => {
    let maps = document.querySelectorAll(".mapbox-map");
    if (!maps.length) return;

    // TODO: Afron, add GUXO access token
    mapboxgl.accessToken =
        "pk.eyJ1IjoiYWZyb25vcmFuYSIsImEiOiJja3Q1bWo1bHkwOXlxMm9vN2UwY3pwNnZrIn0.l2X3614JeqpFqGrPIPtV0A";

    maps.forEach((mapItem) => {
        let mapData = {
            coordinates: mapItem.getAttribute("data-coordinates").split(","),
            title: mapItem.getAttribute("data-title"),
            description: mapItem.getAttribute("data-description"),
        };
        if (mapItem.getAttribute("data-coordinates"));

        let map = new mapboxgl.Map({
            container: mapItem,
            style: "mapbox://styles/mapbox/streets-v11",
            center: mapData.coordinates,
            zoom: 14,
        });

        const el = document.createElement("div");
        el.className = "map-marker";

        // make a marker for each location and add to the map
        new mapboxgl.Marker(el)
            .setLngLat(mapData.coordinates)
            .setPopup(
                new mapboxgl.Popup({ offset: 25, closeButton: false }) // add popups
                    .setHTML(
                        `<h3>${mapData.title}</h3><p>${mapData.description}</p>`
                    )
            )
            .addTo(map);
    });
};
